import React, { Component } from 'react'
import Header from './Header'
import Menu from './Menu'
import routes from './routes/routes'
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import logo from './logo.svg';
import './App.css'
import Footer from './Footer'

const AdminDashboard = () => {

    const user = JSON.parse(localStorage.getItem('userData'));
    return (
        <>
            <div>
                {localStorage.getItem('userData')?
               <Router>
                    <Header />
                    <Menu/>
                    <Switch>
                        {
                            routes.map((route, idx) => {
                                return (
                                    
                                    route.component && (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            render={(props) => (
                                                <route.component {...props} />
                                            )}
                                        />

                                    )
                                )

                            }

                            )
                        }
                    </Switch>
                </Router>
                :
                <Redirect to="/" />

                    }
            

            </div>
        </>
    )

}

export default AdminDashboard;
import axios from 'axios';
import React, { Component } from 'react'

export default class AddService extends Component {
    state = {
        service_name:'',
        service_package:'',
        service_price:''

    }
    handleInput = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        });
    }

    saveService = async (e) => {
        e.preventDefault();
        const res = await axios.post('api/add-service', this.state);

        if(res.data.status="1500"){
            
            this.setState({
                service_name:'',
                service_package:'',
                service_price:''
                
            })
            alert("Successfully Added")
            
        }
    }
    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Service</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveService}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="service_name">Service Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="service_name" name="service_name"  onChange={this.handleInput}  value={this.state.service_name}  placeholder="Enter service name" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="service_package">Service Package
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="service_package" name="service_package"  onChange={this.handleInput}  value={this.state.service_package}  placeholder="Enter service package" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="service_price">Service Price
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="service_price" name="service_price"  onChange={this.handleInput}  value={this.state.service_price}  placeholder="Enter service price" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import axios from 'axios';
import React, { Component } from 'react'
import { MenuItem, Select } from '@material-ui/core';




export default class AddProduct extends Component {
    state = {
        company_name: '',
        product_name: '',
        product_type: '',
        product_size: '',
        purchase_price: '',
        retail_price: '',
        product_code: '',
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    saveProduct = async (e) => {
        e.preventDefault();
        const res = await axios.post('api/add-product', this.state);
        if (res.data.status = "900") {

            this.setState({
                company_name: '',
                product_name: '',
                product_type: '',
                product_size: '',
                purchase_price: '',
                retail_price: '',
                product_code: ''

            })
            alert("Successfully Added")

        }
    }
    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Products</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveProduct}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Brand Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="company_name" name="company_name" onChange={this.handleInput}  value={this.state.company_name} placeholder="Enter brand name" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Product Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="product_name" name="product_name" onChange={this.handleInput}  value={this.state.product_name}placeholder="Enter product name" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_type">Category
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <select className="form-control select2" id="product_type" name="product_type" onChange={this.handleInput}  value={this.state.product_type}>
                                                                    <option value>Please select</option>
                                                                    <option value="Skin">Skin</option>
                                                                    <option value="Face">Face</option>
                                                                    <option value="Hair">Hair</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_size">Product Size
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="product_size" name="product_size" onChange={this.handleInput}  value={this.state.product_size} placeholder="Enter product size" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="val-email">Purchase Price <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="purchase_price" name="purchase_price" onChange={this.handleInput}  value={this.state.purchase_price} placeholder="Enter purchase price" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="retail_price">Retail Price <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="retail_price" name="retail_price" onChange={this.handleInput}  value={this.state.retail_price} placeholder="Enter retail price" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_code">Product Code <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="product_code" name="product_code" onChange={this.handleInput}  value={this.state.product_code} placeholder="Enter product code" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

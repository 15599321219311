import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div >

                    {/* Content Header (Page header) */}
                    

                            <footer classname="main-footer" style={{ position: 'bottom-fixed' }}>
                                <strong>Developed by <a href="https://madadsdigital.com">Mad Ads Digital</a>.</strong>
                            </footer>
                        </div>



        )
    }
}

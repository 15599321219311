import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import AddCompany from './AddCompany';
import Header from './Header';




export default function ViewService() {

  const [data, setData] = useState([]);

  const columns = [
    {
      title: 'ID', field: 'id', width: '10%'

    },
    {
      title: 'Service Code', field: 'service_code', width: '10%'

    },
    {
      title: 'Service Name', field: 'service_name',
    },
    {
      title: 'Section', field: 'service_type',
    },
    {
      title: 'Service Price', field: 'service_price',
    },
    {
      title: 'Is Discounted?', field: 'is_discounted',
    }

  ]

  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => {
    fetch('https://software.madadsdigital.com/backend/public/api/view-service')
      .then(resp => resp.json())
      .then(resp => { console.log(resp); return resp; })
      .then(resp => setData(resp))
  }, [])

  const api = axios.create({
    baseURL: `https://software.madadsdigital.com/backend/public/`
  })

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = []

    if (errorList.length < 1) {
      api.patch("api/update-service/" + oldData.id, newData)
        .then(res => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve()
          setIserror(false)
          setErrorMessages([])
          alert("Successfully Updated")
        })
        .catch(error => {
          setErrorMessages(["Update failed! Server error"])
          setIserror(true)
          resolve()

        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()

    }

  }

  const handleRowAdd = (newData, resolve) => {
    //validation
    let errorList = []

    if (errorList.length < 1) { //no error
      api.post("api/add-service", newData)
        .then(res => {
          let dataToAdd = [...data];
          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve()
          setErrorMessages([])
          setIserror(false)
        })
        .catch(error => {
          setErrorMessages(["Cannot add data. Server error!"])
          setIserror(true)
          resolve()
        })
    } else {
      setErrorMessages(errorList)
      setIserror(true)
      resolve()
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    api.delete("api/delete-service/" + oldData.id)
      .then(res => {
        const dataDelete = [...data];
        const index = oldData.tableData.id;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve()
      })
      .catch(error => {
        setErrorMessages(["Delete failed! Server error"])
        setIserror(true)
        resolve()
      })
  }


  return (
    <div>
      <section className="content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Services</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <MaterialTable
                    title="Service List"
                    data={data}
                    columns={columns}
                    options={{
                      exportButton: true,
                      exportAllData: true,
                      pageSizeOptions: [10, 15],
                      pageSize: 10,
                      addRowPosition: 'first',
                      actionsColumnIndex: -1,
                      grouping: true,
                      columnsButton: true,


                    }}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          handleRowUpdate(newData, oldData, resolve);

                        }),
                      onRowAdd: (newData) =>
                        new Promise((resolve) => {
                          handleRowAdd(newData, resolve)
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          handleRowDelete(oldData, resolve)
                        }),
                    }}
                  />
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  )
}


import React from 'react'
import { useState, useEffect } from 'react';


export default function AdminProfile() {


const user = JSON.parse(localStorage.getItem('userData'));

const { id: id, name:name, email: email, password:password } = user;



const [data, setData] = useState([]);

useEffect(() => {
    fetch('https://software.madadsdigital.com/backend/public/view-password', email)
        .then(resp => resp.json())
        .then(resp => { console.log(resp); return resp; })
        .then(resp => setData(resp))

}, [])


    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-md-3" style={{marginLeft:'auto', marginRight:'auto'}}>
                                    {/* Profile Image */}
                                    <div className="card card-primary card-outline">
                                        <div className="card-body box-profile">
                                            <div className="text-center">
                                                <img className="profile-user-img img-fluid img-circle" src="../../dist/img/user-image.png" alt="User profile picture" />
                                            </div>
                                            <h3 className="profile-username text-center">{name}</h3>
                                            <p className="text-muted text-center">Owner</p>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Email</b> <a className="float-right">{email}</a>
                                                </li>
                                            </ul>
                                            {/* <a href="#" className="btn btn-primary btn-block"><b>Follow</b></a> */}
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                    {/* About Me Box */}

                                    {/* /.card */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
}

import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';

export default function SampleReport() {

    const [data, setData] = useState([]);




    return (
        <div>

        </div>
    )
}

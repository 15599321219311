import axios from 'axios';
import React, { Component } from 'react'

export default class AddCategory extends Component {
    state = {
        category:''
    }
    handleInput = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        });
    }

    saveCategory = async (e) => {
        e.preventDefault();
        const res = await axios.post('api/add-category', this.state);

        if(res.data.status="700"){
            
            this.setState({
                category:''
                
            })
            alert("Successfully Added")
            
        }
    }
    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Category</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveCategory}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="category">Category Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="category" name="category"  onChange={this.handleInput}  value={this.state.category}  placeholder="Enter category name" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

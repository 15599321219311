import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';
import AddStock from './AddStock';
import UpdateStock from './UpdateStock';

export default function ViewStock() {

    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'ID', field: 'id', width:'10%'

        },
        {
            title: 'Product Code', field: 'product_code',
        },
        {
            title: 'Product Name', field: 'product_name',
        },
        {
            title: 'Size', field: 'product_size',
        },
        {
            title: 'Retail Price', field: 'retail_price',
        },
        {
            title: 'Previous Stock', field: 'previous_stock',
        },
        {
            title: 'Sold Out', field: 'sold_out',
        },
        {
            title: 'Balance Stock', field: 'balance_stock',
        },

    ]
    useEffect(() => {
        fetch('https://software.madadsdigital.com/backend/public/api/view-stock')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    const api = axios.create({
        baseURL: `https://software.madadsdigital.com/backend/public/`
      })

      const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []

        
          api.patch("api/edit-stock/"+oldData.id, newData)
          .then(res => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setData([...dataUpdate]);
            resolve()
          })
        
         
      }
      const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-stock/"+oldData.id)
          .then(res => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData([...dataDelete]);
            resolve()
          })
      }


    return (
        <div>
            <section className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Update Stock</h3>
                                </div>
                                
                                {/* /.card-header */}
                                <div className="card-body">
                                    <UpdateStock/>
                                    <MaterialTable
                                        title="Stock List"
                                        data={data}
                                        columns={columns}
                                        options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            pageSizeOptions: [10, 15],
                                            pageSize: 10,
                                            addRowPosition: 'first',
                                            actionsColumnIndex: -1,
                                            grouping:true,
                                            columnsButton:true,


                                        }}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                              new Promise((resolve) => {
                                                  handleRowUpdate(newData, oldData, resolve);
                                                   
                                              }),
                                            onRowDelete: (oldData) =>
                                              new Promise((resolve) => {
                                                handleRowDelete(oldData, resolve)
                                              }),
                                          }}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </div>
    )
}


import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';

export default function ViewProduct() {

    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'ID', field: 'id', width:'10%'

        },
        {
            title: 'Product Name', field: 'product_name',
        },
        {
            title: 'Brand Name', field: 'company_name',
        },
        {
            title: 'Category', field: 'product_type',
        },
        {
            title: 'Size', field: 'product_size',
        },
        {
            title: 'Purchase Price', field: 'purchase_price',
        },
        {
            title: 'Retail Price', field: 'retail_price',
        },
        {
            title: 'Product Code', field: 'product_code',
        },
        {
          title: 'Note', field: 'tags',
      },
      {
        title: 'Source', field: 'source',
    },
    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://software.madadsdigital.com/backend/public/api/view-product')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    const api = axios.create({
        baseURL:`https://software.madadsdigital.com/backend/public/`
      })

    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []

        if(errorList.length < 1){
          api.patch("api/update-product/"+newData.id, newData)
          .then(res => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setData([...dataUpdate]);
            resolve()
            setIserror(false)
            setErrorMessages([])
            alert("Successfully Updated")
          })
          .catch(error => {
            setErrorMessages(["Update failed! Server error"])
            setIserror(true)
            resolve()
             
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
     
        }
         
      }
     
      const handleRowAdd = (newData, resolve) => {
        //validation
        let errorList = []
     
        if(errorList.length < 1){ //no error
          api.post("api/add-product", newData)
          .then(res => {
            let dataToAdd = [...data];
            dataToAdd.push(newData);
            setData(dataToAdd);
            resolve()
            setErrorMessages([])
            setIserror(false)
          })
          .catch(error => {
            setErrorMessages(["Cannot add data. Server error!"])
            setIserror(true)
            resolve()
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
        }
      }

      const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-product/"+oldData.id)
          .then(res => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData([...dataDelete]);
            resolve()
          })
          .catch(error => {
            setErrorMessages(["Delete failed! Server error"])
            setIserror(true)
            resolve()
          })
      }


    return (
        <div>
        
            <section className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Products</h3>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                <div className="card-body" style={{overFlow:'scroll'}}>
                                    <MaterialTable
                                        title="Product List"
                                        data={data}
                                        columns={columns}
                                        options={{
                                            exportButton: true,
                                            importButton:true,
                                            exportAllData: true,
                                            pageSizeOptions: [10, 15],
                                            pageSize: 15,
                                            addRowPosition: 'first',
                                            actionsColumnIndex: 8,
                                            exportFileName:'Products List',
                                            columnsButton:true,
                                            headerStyle: {
                                              position: "sticky",
                                              top: "0"
                                            },
                                            maxBodyHeight: "800px",
                                            grouping:true,


                                        }}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                              new Promise((resolve) => {
                                                  handleRowUpdate(newData, oldData, resolve);
                                                   
                                              }),
                                            onRowAdd: (newData) =>
                                              new Promise((resolve) => {
                                                handleRowAdd(newData, resolve)
                                              }),
                                            onRowDelete: (oldData) =>
                                              new Promise((resolve) => {
                                                handleRowDelete(oldData, resolve)
                                              }),
                                          }}
                                    />
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </div>
    )
}


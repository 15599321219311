import axios from 'axios';
import React, { Component } from 'react'
import Select from 'react-select'


export default class AddDailyHistory extends Component {


    state = {
        scr: '',
        ccr: '',
        petty_expense: '',
        bank_expense: '',
        to_bank: '',
        date: '',

    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }




    saveHistory = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://software.madadsdigital.com/backend/public/api/add-daily-history', this.state);
        if (res.data.status = "900") {

            this.setState({
                scr: '',
                ccr: '',
                petty_expense: '',
                bank_expense: '',
                to_bank: '',
                date: '',
            })
            alert("Successfully Added")

        }
    }

    render() {

        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Daily Accounts</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveHistory}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Date
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="date" className="form-control" id="date" name="date" onChange={this.handleInput} value={this.state.date} placeholder="Select date" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Scr
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="scr" name="scr" onChange={this.handleInput} value={this.state.scr} placeholder="Enter scr" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="reference">Ccr
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="ccr" name="ccr" onChange={this.handleInput} value={this.state.reference} placeholder="Enter ccr" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="petty_expense">Petty Expense <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="petty_expense" name="petty_expense" onChange={this.handleInput} value={this.state.petty_expense} placeholder="Enter petty expense" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="petty_expense">Bank Expense <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="petty_expense" name="bank_expense" onChange={this.handleInput} value={this.state.bank_expense} placeholder="Enter bank expense" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="email">To Bank <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="to_bank" name="to_bank" onChange={this.handleInput} value={this.state.to_bank} placeholder="Enter to bank" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import AddCompany from './AddCompany';
import Menu from './Menu';
import Header from './Header';




export default function ViewCategory() {

    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'ID', field: 'id', width: '10%'

        },
        {
            title: 'Category', field: 'category',
        },
        {
            title: 'Created At', field: 'created_at',
        },
        {
            title: 'Updated At', field: 'updated_at',
        },


    ]
    useEffect(() => {
        fetch('https://software.madadsdigital.com/backend/public/api/view-category')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])


    return (
        <div>
            
            <Header/>
            <section className="content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Category</h3>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <MaterialTable
                                        title="Categories"
                                        data={data}
                                        columns={columns}
                                        options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            pageSizeOptions: [10, 15],
                                            pageSize: 10,
                                            addRowPosition: 'first',
                                            actionsColumnIndex: -1,
                                            grouping:true,
                                            columnsButton: true,


                                        }}
                                        actions={[
                                            {
                                                icon: 'edit',
                                                tooltip: 'Edit User',


                                            },
                                            {
                                                icon: 'delete',
                                                tooltip: 'Delete User',

                                            }
                                        ]}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </div>
    )
}


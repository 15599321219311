import axios from 'axios';
import React, { Component } from 'react'
import Header from './Header';
import ViewMember from './ViewMember';

export default class AddMember extends Component {
    state = {
        member_name: '',
        phone_number:'',
        address:'',
        email:'',
        date_of_birth:'',
        profession:'',
        note:'',
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    saveMember = async (e) => {
        e.preventDefault();
        const res = await axios.post('api/add-member', this.state);

        if (res.data.status = "1200") {

            this.setState({
                member_name: '',
                phone_number:'',
                address:'',
                email:'',
                date_of_birth:'',
                profession:'',

            })
            alert("Successfully Added")

        }
    }
    render() {
        return (
            <div>

                <div className="modal fade" id="modal-primary">
                    <div className="modal-dialog modal-primary">
                        <div className="modal-content">
                            <form className="form-valide" onSubmit={this.saveMember}>
                                <div className="modal-header">
                                    <h4 className="modal-title">Add Member</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <label className="col-lg-4 col-form-label" htmlFor="member_name">Member Name
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="member_name" name="member_name" onChange={this.handleInput} value={this.state.member_name} placeholder="Enter member name" />
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="phone_number">Phone Number
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="phone_number" name="phone_number" maxLength="11" minLength="11" onChange={this.handleInput} value={this.state.phone_number} placeholder="Enter phone number" />
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="address">Address
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="address" name="address" onChange={this.handleInput} value={this.state.address} placeholder="Enter address" />
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="phone_number">Email
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="email" className="form-control" id="email" name="email" onChange={this.handleInput} value={this.state.email} placeholder="Enter email" />
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="date_of_birth">Date of birth
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="date" className="form-control" id="date_of_birth" name="date_of_birth" onChange={this.handleInput} value={this.state.date_of_birth} placeholder="Enter date of birth" />
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="profession">Profession
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="profession" name="profession" onChange={this.handleInput} value={this.state.profession} placeholder="Enter profession" />
                                    <label className="col-lg-4 col-form-label" htmlFor="profession">Note
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="note" name="note" onChange={this.handleInput} value={this.state.note} placeholder="Enter note" />
                                </div>

                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div>
                        {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                </div>

                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Member List</h4>
                                        <button type="button" data-toggle="modal" data-target="#modal-primary" className="btn btn-success float-right">
                                            <i className="fas fa-plus" /> Add Member
                                        </button>
                                    </div>
                                    <ViewMember />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';

export default function SearchProductInvoice() {


    const [data, setData] = useState([]);
    const [data2,setData2] = useState([]);


    const columns = [
        {
            title: 'Product Name', field: 'product_name',
        },
        {
            title: 'Qt', field: 'quantity', width: '10%'

        },

        {
            title: 'Size', field: 'product_size',
        },
        {
            title: 'Price', field: 'price',
        },
        {
            title: 'Discount', field: 'discount_percentage',
        },
        {
            title: 'Subtotal', field: 'final_price',
        },
    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('http://localhost:8000/api/view-latest-sale')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    useEffect(() => {
        fetch('http://localhost:8000/api/view-total-price')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData2(resp))

    }, [])
      

    const api = axios.create({
        baseURL: `http://localhost:8000/`
    })

    const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-product/" + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }


    return (
        <div>
            {/* /.card-header */}
            <div className="invoice p-3 mb-3" id="invoice">
                {/* title row */}
                <div className="row">
                    <div className="col-12">
                        <h4>
                            <i className="fas fa-globe" /> FEmme Beauty Clinic & Parlor
                        </h4>
                    </div>
                    {/* /.col */}
                </div>
                {/* info row */}
                <div className="row invoice-info">
                    <div className="col-sm-4 invoice-col">
                        From
                        <address>
                            <strong>Admin, Inc.</strong><br />
                            Noyasarak, Sylhet<br />
                            Phone: 01712115562<br />
                        </address>
                    </div>
                    {/* /.col */}
                    <div className="col-sm-4 invoice-col">
                        To
                        <address>
                            <strong></strong><br />
                            {
                                data.map((row, i)=>{
                                    if(i==0){
                                    return(
                                        <b> {row.customer_name} </b>
                                    )
                                    }
                                })
                            }
                             <br />
                            
                            {
                                data.map((row, i)=>{
                                    if(i==0){
                                    return(
                                        <address>Phone: {row.customer_number}</address> 
                                    )
                                    }
                                })
                            }<br />

                        </address>
                    </div>
                    {/* /.col */}
                    <div className="col-sm-4 invoice-col">
                    <b>Date:  {new Date().toLocaleString()}

                            
                        </b>
                        <br/>
                        <b>Invoice:
                            {
                                data.map((row, i)=>{
                                    if(i==0){
                                    return(
                                        <b> {row.invoice_number} </b>
                                    )
                                    }
                                })
                            }
                            
                        </b>
                        <br />
                    </div>
                    {/* /.col */}
                </div>
                {/* /.row */}
                {/* Table row */}
                <div className="row">

                        {/* /.col */}
                    </div>
                    <MaterialTable
                        data={data}
                        columns={columns}
                        options={{
                            pageSizeOptions: [5, 10],
                            pageSize: 20,
                            addRowPosition: 'first',
                            actionsColumnIndex: 0,
                            search: false,
                            showTitle: false,
                            paging: false

                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //         new Promise((resolve) => {
                        //             handleRowDelete(oldData, resolve)
                        //         }),
                        // }}
                    />
                {/* /.row */}
                <div className="row">
                    {/* accepted payments column */}
                    <div className="col-6">
                        <p className="lead">Payment Methods:</p>
                        <img src="../../dist/img/credit/visa.png" alt="Visa" />
                        <img src="../../dist/img/credit/mastercard.png" alt="Mastercard" />
                        <img src="../../dist/img/credit/american-express.png" alt="American Express" />
                        <img src="../../dist/img/credit/bkash.png" alt="Bkash" />
                        <p className="text-muted well well-sm shadow-none" style={{ marginTop: 10 }}>
                            "FEmmE" first time ever in Sylhet a 'Beauty Clinic & Parlour'
                            to provide quality services
                            in an exquisite atmosphere by reasonable price range.
                        </p>
                    </div>
                    {/* /.col */}
                    <div className="col-6">
                        <p className="lead">Amount Due 2/22/2014</p>
                        <div className="table-responsive">
                            <table className="table">
                                <tbody><tr>
                                    <th style={{ width: '50%' }}>Subtotal:</th>
                                    <td>৳
                                    {data2 }
                                    </td>
                                </tr>
                                    <tr>
                                        <th>Total:</th>
                                        <td>৳{data2}</td>
                                    </tr>
                                </tbody></table>
                        </div>
                    </div>
                    
                    {/* /.col */}
                </div>
                {/* /.row */}
                {/* this row will not appear when printing */}

            </div>


        </div>
    )
}


import React from 'react';
import {Bar} from 'react-chartjs-2';
import axios from 'axios';
class BarChart extends React.Component {
    constructor(props) {
        super(props)
          this.state = {
            month: [],
            sale:[],
            expenditure:[]
                  }
          }
     
      componentDidMount(){
        //Get all chart details
        axios.get('https://software.madadsdigital.com/backend/public/api/view-monthly-chart')
        .then(res => 
        {
        //Storing users detail in state array object
        this.setState({month: res.data[0]});
        this.setState({sale: res.data[1]});
        this.setState({expenditure: res.data[2]});
           }); 
       
        
        }
    
 
  render() {
   
    return (
     
    <div>
       
            <Bar
                data={{
                    labels: this.state.month,
                    datasets: [
                        {
                            // Label for bars
                            label: "Total Sale",
                            // Data or value of your each variable
                            data: this.state.sale,


                            // Color of each bar
                            backgroundColor: ["#CDCED0", "#CDCED0", "#CDCED0", "#CDCED0"],
                            // Border color of each bar
                            borderColor: ["#CDCED0", "#CDCED0", "#CDCED0", "#CDCED0"],
                            borderWidth: 0.5,
                        },
                        {
                            // Label for bars
                            label: "Total Expenditure",
                            // Data or value of your each variable
                            data: this.state.expenditure ,
                            // data: [499,245,228,194,483,564,455,645,708,162,322,423],
                            // Color of each bar
                            backgroundColor: ["#F5E188", "#F5E188", "#F5E188", "#F5E188"],
                            // Border color of each bar
                            borderColor: ["black", "black", "black", "black"],
                            borderWidth: 0.5,
                        },
                    ]
                  }}
                  height={600}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    // The y-axis value will start from zero
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    legend: {
                        labels: {
                            fontSize: 20,
                        },
                    },
                }}
                />
    </div>
     
      
)
};
}
export default BarChart;
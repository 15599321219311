import React, { Component } from 'react'
import { useState, useEffect } from 'react';
import Menu from './Menu'
import Header from './Header'
import ViewProduct from './ViewProduct'
import MonthlyTotalSale from './statistics/MonthlyTotalSale'
import MaterialTable from 'material-table';
import ShortItems from './ShortItems';
import Footer from './Footer';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import BarChart from './BarChart';
import { Redirect } from 'react-router';

export default function Dashboard() {



    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('https://software.madadsdigital.com/backend/public/api/view-monthly-stats')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    const user = JSON.parse(localStorage.getItem('userData'));

    const { id: id, name: name, email: email, password: password, account_type:account_type } = user;
    if(account_type==='seller'){
        return <Redirect to="/admin/add-product-sale" />;
    }
    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Admin Dashboard</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box " style={{background:'#343A40', color:'#EEB609'}} >
                                <div className="inner">
                                    <h3>{data[0]}</h3>
                                    <p>Total Monthly Sale</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <a href="/admin/view-all-sale" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>

                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box" style={{background:'#343A40', color:'#EEB609'}}>
                                <div className="inner">
                                    <h3>{data[3]}</h3>
                                    <p>Total Monthly Expenditure</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-ios-redo" />
                                </div>
                                <a href="/admin/view-expense" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box" style={{background:'#343A40', color:'#EEB609'}}>
                                <div className="inner">
                                    <h3>{data[6]}</h3>
                                    <p>Profit/Loss</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-ios-calculator" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6" >
                            {/* small box */}
                            <div className="small-box " style={{background:'#343A40', color:'#EEB609'}}>
                                <div className="inner">
                                    <h3>{data[5]}</h3>
                                    <p>Avg Sale/Day</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-pie-graph" />
                                </div>
                                <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}
                        {/* ./col */}

                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box" style={{background:'#EEB609', color:'black'}}>
                                <div className="inner">
                                    <h3>{data[1]}</h3>
                                    <p>Monthly Cosmetic Sale</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                <a href="/admin/add-member" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box" style={{background:'#EEB609', color:'black'}} >
                                <div className="inner">
                                    <h3>{data[2]}</h3>
                                    <p>New Customer</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add" />
                                </div>
                                <a href="/admin/add-member" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>
                        {/* ./col */}
                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box " style={{background:'#EEB609', color:'black'}}>
                                <div className="inner">
                                    <h3>{data[7]}</h3>
                                    <p>New Member</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person-add" />
                                </div>
                                <a href="/admin/add-member" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-6">
                            {/* small box */}
                            <div className="small-box " style={{background:'#EEB609', color:'black'}}>
                                <div className="inner">
                                    <h3>{data[4]} %<sup style={{ fontSize: 20 }}></sup></h3>
                                    <p>ROI</p>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-stats-bars" />
                                </div>
                                <a href="/admin/monthly-report" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                            </div>
                        </div>

                        {/* ./col */}
                    </div>


                    <div className="row">
                        <div className="col-lg-6">
                            <BarChart/>
                        </div>
                        <div className="col-lg-6">
                            <ShortItems />
                        </div>
                        
                    </div>

                    {/* /.col-md-6 */}
                </div>
                {/* /.row */}
                <Footer />
            </div>

            {/* /.container-fluid */}
        </div>


    )
}


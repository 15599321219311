import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';

export default function ProductList() {

  const [data, setData] = useState([]);

  const columns = [
    {
      title: 'ID', field: 'id', width: '10%'

    },
    {
      title: 'Product Name', field: 'product_name',
    },
    {
      title: 'Size', field: 'product_size',
    },
    {
      title: 'Purchase Price', field: 'purchase_price',
    },
    {
      title: 'Retail Price', field: 'retail_price',
    },
    {
      title: 'Product Code', field: 'product_code',
    },
  ]

  const [iserror, setIserror] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(() => {
    fetch('https://software.madadsdigital.com/backend/public/api/view-product')
      .then(resp => resp.json())
      .then(resp => { console.log(resp); return resp; })
      .then(resp => setData(resp))

  }, [])

  const api = axios.create({
    baseURL: `https://software.madadsdigital.com/backend/public/`
  })



  return (
    <div>
      <div className="col-lg-12">
        <MaterialTable
          title="Product List"
          data={data}
          columns={columns}
          options={{
            exportButton: false,
            exportAllData: false,
            pageSizeOptions: [10, 15],
            pageSize: 10,
            addRowPosition: 'first',
            actionsColumnIndex: -1,
            columnsButton: false,
            headerStyle: {
              position: "sticky",
              top: "0"
            },
            maxBodyHeight: "850px",
            grouping:true,
          }}
        />
      </div>
      {/* /.col */}

    </div>
  )
}


import React, { Component } from 'react'

export default class Bill extends Component {
    render() {
        return (
            <div>
                <section className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5  mt-4 ">
                                <table className="table" style={{ backgroundColor: '#e0e0e0' }}>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Item</th>
                                            <th style={{ width: '31%' }}>Qty</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row">1</td>
                                            <td style={{ width: '60%' }}>
                                                <select name="vegitable" id="vegitable" className="form-control">
                                                    @foreach($orders as $row )
                                                    <option id="{{$row-">id{'}'}{'}'} value={'{'}{'{'}$row-&gt;product_name{'}'}{'}'} class="vegitable custom-select"&gt;
                                                        {'{'}{'{'}$row-&gt;product_name{'}'}{'}'}
                                                    </option>
                                                    @endforeach
                                                </select>
                                            </td>
                                            <td style={{ width: '1%' }}>
                                                <input type="number" id="qty" min={0} defaultValue={0} className="form-control" />
                                            </td>
                                            <td>
                                                <h5 className="mt-1" id="price" />
                                            </td>
                                            <td><button id="add" className="btn btn-success">Add</button></td>
                                        </tr>
                                        <tr>
                                        </tr>
                                    </tbody>
                                </table>
                                <div role="alert" id="errorMsg" className="mt-5">
                                    {/* Error msg  */}
                                </div>
                            </div>
                            <div className="col-md-7  mt-4" style={{ backgroundColor: '#f5f5f5' }}>
                                <div className="p-4">
                                    <div className="text-center">
                                        <h4>Receipt</h4>
                                    </div>
                                    <span className="mt-4"> Time : </span><span className="mt-4" id="time" />
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6 col-md-6 ">
                                            <span id="day" /> : <span id="year" />
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6 text-right">
                                            <p>Order No:1234</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <table id="receipt_bill" className="table">
                                            <thead>
                                                <tr>
                                                    <th> No.</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th className="text-center">Price</th>
                                                    <th className="text-center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody id="new">
                                            </tbody>
                                            <tbody><tr>
                                                <td> </td>
                                                <td> </td>
                                                <td> </td>
                                                <td className="text-right text-dark">
                                                    <h5><strong>Sub Total:  ৳ </strong></h5>
                                                    <p><strong>Tax (5%) :  ৳ </strong></p>
                                                </td>
                                                <td className="text-center text-dark">
                                                    <h5> <strong><span id="subTotal" /></strong></h5>
                                                    <h5> <strong><span id="taxAmount" /></strong></h5>
                                                </td>
                                            </tr>
                                                <tr>
                                                    <td> </td>
                                                    <td> </td>
                                                    <td> </td>
                                                    <td className="text-right text-dark">
                                                        <h5><strong>Gross Total: ৳ </strong></h5>
                                                    </td>
                                                    <td className="text-center text-danger">
                                                        <h5 id="totalPayment"><strong> </strong></h5>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></section>

                    

            </div>
        )
    }
}



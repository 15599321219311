import React, { Component, useHistory } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import { withRouter } from "react-router";

export default class Login extends Component {



    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            account_type: '',
            redirect: false,


        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    onSignInHandler = async (e) => {
        e.preventDefault();
        axios.post('https://software.madadsdigital.com/backend/public/api/login', {
            email: this.state.email,
            password: this.state.password,
        })
            .then((response) => {
                if (response.data.status === "400") {
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("userData", JSON.stringify(response.data.data));
                    this.setState({
                        redirect: true,
                        email: this.state.email,
                        account_type: response.data.data.account_type
                    });
                    alert("Successfully logged in")

                }
                else if (response.data.status === "300") {
                    alert("Wrong email or password!")
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {


        const login = localStorage.getItem("isLoggedIn");
        const data = localStorage.getItem("userData");
        console.log(data)

        if (this.state.redirect && this.state.account_type === 'admin') {
            return <Redirect to="/admin/dashboard" />;

        }
        console.log(data);
        if (login && this.state.account_type === 'admin') {

            return <Redirect to="/admin/dashboard" />;
        }
        else if (this.state.redirect && this.state.account_type === 'seller') {
            return <Redirect to="/admin/add-product-sale" />;
        }
        else if (login && this.state.account_type === 'seller') {
  
            return <Redirect to="/admin/add-product-sale" />;
        }

        return (
            <div>
                <body className="hold-transition register-page">
                    <div className="login-box">
                        {/* /.login-logo */}
                        <div className="card card-outline card-primary">
                            <div className="card-header text-center">
                                <a href="../../index2.html" className="h1"><b>FEmmE</b></a>
                            </div>
                            <div className="card-body">
                                <p className="login-box-msg">Sign in to start your session</p>
                                <form>
                                    <div className="input-group mb-3">
                                        <input type="email" className="form-control" onChange={this.onChangehandler} name="email" value={this.state.email} placeholder="Email" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" className="form-control" onChange={this.onChangehandler} name="password" value={this.state.password} placeholder="Password" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* /.col */}
                                        <div className="col-4">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={this.onSignInHandler}>

                                                Sign In


                                            </button>

                                        </div>
                                        {/* /.col */}
                                    </div>
                                </form>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                    {/* /.login-box */}
                </body>
            </div>
        );
    }
}
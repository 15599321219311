import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { useState, useEffect } from 'react';
import Header from './Header';

export default function ProductCart() {


    const [data, setData] = useState([]);
    const [data2,setData2] = useState([]);


    const columns = [
        {
            title: 'Product Name', field: 'product_name',
        },
        {
            title: 'Size', field: 'product_size',
        },
        {
            title: 'Price', field: 'unit_price',
        },
        {
            title: 'Qt', field: 'quantity', width: '10%'

        },
        {
            title: 'Discount', field: 'discount',
        },
        {
            title: 'Subtotal', field: 'final_price',
        },
    ]

    const [iserror, setIserror] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        fetch('https://software.madadsdigital.com/backend/public/api/view-latest-sale')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData(resp))

    }, [])

    useEffect(() => {
        fetch('https://software.madadsdigital.com/backend/public/api/view-total-price')
            .then(resp => resp.json())
            .then(resp => { console.log(resp); return resp; })
            .then(resp => setData2(resp))

    }, [])
      

    const api = axios.create({
        baseURL: `https://software.madadsdigital.com/backend/public/`
    })

    const handleRowUpdate = (newData, oldData, resolve) => {
        //validation
        let errorList = []
        if(newData.company_name === ""){
          errorList.push("Please enter company name")
        }

        if(errorList.length < 1){
          api.patch("api/edit-product-sale/"+oldData.id, newData)
          .then(res => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setData([...dataUpdate]);
            resolve()
            setIserror(false)
            setErrorMessages([])
            alert("Successfully Updated")
            window.location.reload()
          })
          .catch(error => {
            setErrorMessages(["Update failed! Server error"])
            setIserror(true)
            resolve()
             
          })
        }else{
          setErrorMessages(errorList)
          setIserror(true)
          resolve()
     
        }
         
      }

    const handleRowDelete = (oldData, resolve) => {
        api.delete("api/delete-product-sale/" + oldData.id)
            .then(res => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                resolve()
            })
            .catch(error => {
                setErrorMessages(["Delete failed! Server error"])
                setIserror(true)
                resolve()
            })
    }


    return (
        <div>
            {/* /.card-header */}
            <div className="col-12" id="invoice">
                <div className="row">
                        {/* /.col */}
                    </div>
                    <MaterialTable
                        data={data}
                        columns={columns}
                        options={{
                            pageSizeOptions: [5, 10],
                            pageSize: 20,
                            addRowPosition: 'first',
                            actionsColumnIndex: -1,
                            search: false,
                            showTitle: false,
                            paging: false,
                            toolbar:false, 

                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                handleRowUpdate(newData, oldData, resolve);
                                 
                            }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    handleRowDelete(oldData, resolve)
                                }),
                        }}
                    />
                {/* /.row */}
                <div className="row">
                    {/* /.col */}
                    <div className="col-6" >
                        <div className="table-responsive" >
                            <table className="table" >
                                <tbody><tr >
                                    <th style={{ width: '50%' }}>Subtotal:</th>
                                    <td>৳
                                    {data2 }
                                    </td>
                                </tr>
                                    <tr>
                                        <th>Total:</th>
                                        <td>৳{data2}</td>
                                    </tr>
                                </tbody></table>
                        </div>
                    </div>
                    
                    {/* /.col */}
                </div>
                {/* /.row */}
                {/* this row will not appear when printing */}
            </div>


        </div>
    )
}

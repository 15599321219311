import axios from 'axios';
import React, { Component } from 'react'
import ProductList from './ProductList';
import Invoice from './Invoice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { data } from 'jquery';
import Select from 'react-select';
import ServiceList from './ServiceList';
import ServiceInvoice from './ServiceInvoice';
import Footer from './Footer';
import ServiceCart from './ServiceCart';

export default class AddServiceSale extends Component {

    state = {
        invoice_number: Math.floor(Math.random() * (1000000000 - 5 + 1)) + 5,
        customer_name: '',
        customer_number: '',
        id: '',
        service_name: '',
        service_package: '',
        payment_type: '',
        paid: '',
        received:'',
        discount: '0',
        custom_service: '',
        custom_price: '',
        quantity: '1',
    }

    constructor(props) {
        super(props)
        this.state = {
            selectOptions: [],
            invoice_number: Math.floor(Math.random() * (1000000000 - 5 + 1)) + 5,
            id: '',
            service_name: '',
            quantity: '1',
            discount: '0',
        }
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    serviceHandleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.getOptions();
    }

    async getOptions() {
        const res = await axios.get('https://software.madadsdigital.com/backend/public/api/view-available-service')
        const data = res.data
        const filtered_data = data.filter(data => {
            return data.service_type === this.state.service_type;

        })
        const options = filtered_data.map(d => ({
            "id": d.id,
            "label": d.service_code + " " + d.service_name + " " + d.service_type + " " + "Price: " + d.service_price + " ৳ "

        }))

        this.setState({ selectOptions: options })

    }


    handleChange(e) {
        this.setState({ id: e.id, service_name: e.label })
    }


    componentDidMount() {
        this.getOptions()
    }

    saveItem = async (e) => {
        e.preventDefault();
        console.log(this.state)
        const res = await axios.post('https://software.madadsdigital.com/backend/public/api/add-service-sale', this.state);

        if (res.data.status == "1600") {
            console.log(res.status)
            console.log(res.data.message)
            toast(this.state.service_name)
            this.setState({
                service_name: '',
                id: '',
                quantity: '',
                discount: '0',
                quantity: '1',
                custom_service: '',
                custom_price: '',

            })
        }
        else if (res.data.status == "1900") {
            console.log(res.status)
            console.log(res.data.message)
            toast("Clear the invoice first!")
            this.setState({
                service_name: '',
                id: '',
                quantity: '1',

            })
        }

    }

    savePayment = async (e) => {
        e.preventDefault();
        console.log(this.state)
        const res = await axios.post('https://software.madadsdigital.com/backend/public/api/add-service-cash-in', this.state);
        if (res.data.status = "1200") {
            alert("Payment submitted")
            this.setState({
                payment_type: '',
                paid: '',
                received:'',
            })
            window.location.reload();
        }
    }

    cancelInvoice = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://software.madadsdigital.com/backend/public/api/cancel-service-sale');
        if (res.status = "2500") {
            toast("Sale canceled")
            window.location.reload();
        }

    }

    clearServiceInvoice = async (e) => {
        e.preventDefault();

        const res = await axios.post('https://software.madadsdigital.com/backend/public/api/clear-invoice');
        if (res.status = "2500") {
            toast("Invoice Cleared")
            window.location.reload();
        }

    }

    generateInvoice = async (e) => {
        e.preventDefault();
        toast("Invoice generated")
        window.location.reload();
        const res = await axios.fetch('https://software.madadsdigital.com/backend/public/api/view-latest-service-sale');
        if (res.data.status = "1800") {
            this.setState({
                invoice_number: '',
                quantity: ''
            })
        }

    }

    print() {
        window.print(document.getElementById('invoice'));
    }

    render() {

        return (
            <div>
                {/* /.payment-modal-content */}
                <div className="modal fade" id="modal-primary">
                    <div className="modal-dialog modal-primary">
                        <div className="modal-content">
                            <form className="form-valide" onSubmit={this.savePayment}>
                                <div className="modal-header">
                                    <h4 className="modal-title">Payment</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <label className="col-lg-4 col-form-label" htmlFor="product_name">Payment Type
                                        <span className="text-danger">*</span>
                                    </label>

                                    <select className="form-control select2" id="payment_type" name="payment_type" onChange={this.handleInput} value={this.state.payment_type} >
                                        <option value>Please select</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Money Transfer">Money Transfer</option>
                                        <option value="Card/Cheque">Card/Cheque</option>
                                    </select>

                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="product_name">Total Received
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="received" name="received" onChange={this.handleInput} value={this.state.received} placeholder="Enter received amount" />
                                    <br></br>
                                    <label className="col-lg-4 col-form-label" htmlFor="product_name">Payment
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="paid" name="paid" onChange={this.handleInput} value={this.state.paid} placeholder="Enter paid amount" />

                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" >Confirm Payment</button>
                                </div>
                            </form>
                        </div>
                        {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                </div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 no-print" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">FEmmE Invoice</h4>
                                    </div>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={500000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.saveItem}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="customer_number">Customer Number
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" minLength="11" maxLength="11" className="form-control" id="customer_number" name="customer_number" onChange={this.handleInput} value={this.state.customer_number} placeholder="Enter customer number" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Customer Name
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="customer_name" name="customer_name" onChange={this.handleInput} value={this.state.customer_name} placeholder="Enter customer name" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="service_type">Section
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <select className="form-control select2" id="service_type" name="service_type" onChange={this.serviceHandleInput} value={this.state.service_type}>
                                                                    <option value>Please select</option>
                                                                    <option value="Facial">Facial</option>
                                                                    <option value="Haircut">Haircut</option>
                                                                    <option value="Threading">Threading</option>
                                                                    <option value="Makeup">Makeup</option>
                                                                    <option value="Spa">Spa</option>
                                                                    <option value="Hair Rebonding">Hair Rebonding</option>
                                                                    <option value="Bridal">Bridal</option>
                                                                    <option value="Hair Treatment">Hair Treatment</option>
                                                                    <option value="Massage">Massage</option>
                                                                    <option value="Hair Makeover">Hair Makeover</option>
                                                                    <option value="Piercing">Piercing</option>
                                                                    <option value="Package">Package</option>
                                                                    <option value="Other">Other</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="service">Select Service
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6" style={{zIndex:1000}}>
                                                                <Select options={this.state.selectOptions} name="service_name" id="service_name" onChange={this.handleChange.bind(this)} />
                                                            </div>
                                                        </div>
                                                        {this.state.service_name === '' ?
                                                            <>
                                                                <div className="form-group row">
                                                                    <label className="col-lg-4 col-form-label" htmlFor="custom_servoice">Custom Service
                                                                        <span className="text-danger">*</span>
                                                                    </label>
                                                                    <div className="col-lg-6">
                                                                        <input type="text" maxLength="100" className="form-control" id="custom_service" name="custom_service" onChange={this.handleInput} value={this.state.custom_service} placeholder="Enter custom service" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-lg-4 col-form-label" htmlFor="custom_price">Custom Price
                                                                        <span className="text-danger">*</span>
                                                                    </label>
                                                                    <div className="col-lg-6">
                                                                        <input type="text" maxLength="100" className="form-control" id="custom_price" name="custom_price" onChange={this.handleInput} value={this.state.custom_price} placeholder="Enter custom price" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>

                                                        }
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="quantity">Quantity
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="quantity" name="quantity" onChange={this.handleInput} value={this.state.quantity} placeholder="Enter quantity" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="discount">Discount
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="number" className="form-control" id="discount" name="discount" onChange={this.handleInput} value={this.state.discount} placeholder="Enter discount amount" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" onClick={this.saveItem} className="btn btn-success"><i className="fas fa-plus" /> Add Item</button>



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <ServiceCart />
                                        <div className="col-12">
                                            <form onSubmit={this.cancelInvoice}>
                                                <button type="submit" className="btn btn-danger float-left"><i className="fas fa-close" />
                                                    Cancel Sale
                                                </button>
                                            </form>
                                            <form onSubmit={this.clearServiceInvoice}>
                                                <button type="submit" className="btn btn-success float-left" style={{ marginLeft: '5px' }}><i className="fas fa-broom" />
                                                    Clear invoice
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* /.invoice */}
                            </div>
                            <div className="col-lg-4" float="auto" >
                                <div id="invoice">
                                    <div className="row">
                                        <ServiceInvoice
                                        />
                                    </div>
                                    {/* /.row */}
                                    {/* /.row */}
                                    {/* this row will not appear when printing */}
                                </div>
                                <div className="col-lg-6 no-print" float="auto" >
                                    <button type="submit" onClick={this.generateInvoice} className="btn btn-success" style={{ marginBottom: '10px', display:'block', width:'100%' }} ><i className="fas fa-file-invoice-dollar" /> Generate Invoice</button>
                                    <button type="button"  data-toggle="modal" data-target="#modal-primary" className="btn btn-warning" style={{ marginBottom: '10px', display:'block', width:'100%' }}><i className="far fa-credit-card" /> Submit
                                        Payment
                                    </button>
                                    <button type="button" className="btn btn-primary " onClick={this.print} style={{ marginBottom: '10px', display:'block', width:'100%' }}>
                                        <i className="fas fa-print" /> Print Invoice
                                    </button>
                                    <form onSubmit={this.clearServiceInvoice}>
                                        <button type="submit" className="btn btn-danger float-left" style={{ marginBottom: '10px', display:'block', width:'100%' }}>
                                            <i className="fas fa-broom" />
                                             Clear invoice
                                        </button>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import axios from 'axios';
import React, { Component } from 'react'
import { MenuItem, Select } from '@material-ui/core';
import DueList from './DueList';




export default class AddDuePayment extends Component {
    state = {
        invoice_number: '',
        payment_method: '',
        amount: '',
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    savePayment = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://software.madadsdigital.com/backend/public/api/add-due-payment', this.state);
        if (res.data.status = "900") {

            this.setState({
                invoice_number: '',
                payment_method: '',
                amount: '',

            })
            alert("Successfully Added")

        }
    }
    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Add Due Payment</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-validation">
                                            <form className="form-valide" onSubmit={this.savePayment}>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="invoice_number">Invoice Number
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="invoice_number" name="invoice_number" onChange={this.handleInput} value={this.state.invoice_number} placeholder="Enter invoice number" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">

                                                            <label className="col-lg-4 col-form-label" htmlFor="product_name">Payment Method
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <select className="form-control select2" id="payment_method" name="payment_method" onChange={this.handleInput} value={this.state.payment_method}>
                                                                    <option value>Please select</option>
                                                                    <option value="Cash">Cash</option>
                                                                    <option value="Money Transfer">Money Transfer</option>
                                                                    <option value="Card/Cheque">Card/Cheque</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-lg-4 col-form-label" htmlFor="product_size">Amount
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <input type="text" className="form-control" id="amount" name="amount" onChange={this.handleInput} value={this.state.amount} placeholder="Enter amount" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-lg-8 ml-auto">
                                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <DueList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import axios from 'axios';
import React, { Component } from 'react'
import SearchProductInvoice from './SearchProductInvoice';

export default class ViewProductInvoice extends Component {
    state = {
        invoice_number: ''
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    SearchProductInvoice = async (e) => {
        e.preventDefault();
        const res = await axios.post('api/search-product-invoice', this.state);
        if (res.data.status = "700") {

            this.setState({
                category: ''

            })
            alert("Successfully Added")

        }
    }
    render() {
        return (
            <div>
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12" float="auto">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Invoice</h4>
                                    </div>
                                    <div className="col-6" style={{ marginRight: "auto", marginLeft: "auto" }}>
                                        <form onSubmit={this.searchProductInvoice}>
                                            <div className="input-group">
                                                <input type="search" className="form-control form-control-lg" placeholder="Enter invoice number" />
                                                <div className="input-group-append">
                                                    <button type="submit" className="btn btn-lg btn-default">
                                                        <i className="fa fa-search" />
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-6" style={{ marginRight: "auto", marginLeft: "auto" }}>
                                            <SearchProductInvoice />
                                        </div>
                                        <div className="col-6" style={{ marginRight: "auto", marginLeft: "auto" }}>
                                        <button type="button" className="btn btn-primary float-right" onClick={this.print} style={{ marginRight: 5 }}>
                                            <i className="fas fa-print" /> Generate PDF
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, NavLink, Link, BrowserRouter } from 'react-router-dom'
import { Redirect } from 'react-router';
import Footer from './Footer';
import { useState } from 'react';

export default class Menu extends Component {

    state = {
        logout: false,
    };

    onLogoutHandler = () => {
        window.location.reload();
        localStorage.clear();
        this.setState({
            logout: true,
        });
    };


    render() {
        const user = JSON.parse(localStorage.getItem("userData"));
        const { logout } = this.state;
        if (logout) {

            return <Redirect to="/" push={true} />;

        }
        const { id: id, name: name, email: email, password: password, account_type: account_type } = user;


        return (

            <div>
                <div className='wrapper'>
                    <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ overflowY: "auto", position: "fixed", maxHeight: '1600px' }}>
                        {/* Brand Logo */}

                        <NavLink to="/admin/dashboard" className="brand-link" style={{ textAlign: 'center' }}>
                            <span className="brand-text font-weight-light">FEmmE</span>
                        </NavLink>
                        {/* Sidebar */}
                        <div className="sidebar">
                            {/* Sidebar user panel (optional) */}
                            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                                <div className="image">
                                    <img src="/dist/img/user-image.png" className="img-circle elevation-2" alt="User Image" />
                                </div>
                                {account_type === 'admin' ?
                                    <div className="info">
                                        <a href="/admin/dashboard" className="d-block">ADMIN</a>
                                    </div>
                                    :
                                    <div className="info">
                                        <a href="/admin/add-product-sale" className="d-block">SELLER</a>
                                    </div>
                                }
                            </div>
                            {/* SidebarSearch Form */}
                            <div className="form-inline">
                                <div className="input-group" data-widget="sidebar-search">
                                    <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-sidebar">
                                            <i className="fas fa-search fa-fw" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Sidebar Menu */}
                            <nav className="mt-2">

                                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                    {/* Add icons to the links using the .nav-icon class
                        with font-awesome or any other icon font library */}
                                    {/* <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-th-large" />
                                        <p>
                                            Brand
                                            <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>

                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/admin/add-company" exact className="nav-link">
                                                <i className="fas fa-plus nav-icon" />
                                                <p>Add Brand</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/admin/view-company" className="nav-link">
                                                <i className="fas fa-eye nav-icon" />
                                                <p>View Brand</p>
                                            </NavLink>
                                        </li>
                                    </ul>

                                </li> */}

                                    {/* <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-th-large"  />
                                        <p>
                                            Category
                                            <i className="fas fa-angle-left right" />
                                        </p>
                                    </a>

                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/admin/add-category" exact className="nav-link">
                                                <i className="fas fa-plus nav-icon" />
                                                <p>Add Category</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/admin/view-category" className="nav-link">
                                                <i className="fas fa-eye nav-icon" />
                                                <p>View Category</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li> */}
                                    {/* <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon fab fa-product-hunt" />
                                            <p>
                                                Products
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>

                                        <ul className="nav nav-treeview">
                                            {/* <li className="nav-item">
                                            <NavLink to="/admin/add-product" exact className="nav-link">
                                                <i className="fas fa-plus nav-icon" />
                                                <p>Add Products</p>
                                            </NavLink>
                                        </li> */}
                                    {/* <li className="nav-item">
                                                <NavLink to="/admin/view-product" className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View Products</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    // </li> */}
                                    <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon far fa-hand-scissors" />
                                            <p>
                                                Services
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>

                                        <ul className="nav nav-treeview">
                                            {/* <li className="nav-item">
                                            <NavLink to="/admin/add-service" exact className="nav-link">
                                                <i className="fas fa-plus nav-icon" />
                                                <p>Add Services</p>
                                            </NavLink>
                                        </li> */}
                                            <li className="nav-item">
                                                <NavLink to="/admin/view-service" className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View Services</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon fas fa-boxes" />
                                            <p>
                                                Inventory
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>

                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/add-stock" exact className="nav-link">
                                                    <i className="fas fa-plus nav-icon" />
                                                    <p>Add To Stock</p>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/admin/view-stock" className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>Update Stock</p>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/admin/view-product" className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>Product List</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon fas fa-users" />
                                            <p>
                                                Members
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="add-member" exact className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View Member</p>
                                                </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                            <NavLink to="view-member" className="nav-link">
                                                <i className="fas fa-eye nav-icon" />
                                                <p>View Member</p>
                                            </NavLink>
                                        </li> */}
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon fas fa-cart-plus" />
                                            <p>
                                                Sales
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>

                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/add-product-sale" exact className="nav-link">
                                                    <i className="fas fa-plus nav-icon" />
                                                    <p>Add Product Sale</p>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/admin/add-service-sale" exact className="nav-link">
                                                    <i className="fas fa-plus nav-icon" />
                                                    <p>Add Service Sale</p>
                                                </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink to="/admin/view-all-sale" className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View All Sale</p>
                                                </NavLink>
                                            </li> */}
                                            <li className="nav-item">
                                                <NavLink to="/admin/sold-products" className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View Sold Products</p>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/admin/sold-services" className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View Sold Services</p>
                                                </NavLink>
                                            </li>
                                        </ul>


                                    </li>

                                    <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon fas fa-coins" />
                                            <p>
                                                Payment
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/add-due-payment" exact className="nav-link">
                                                    <i className="fas fa-plus nav-icon" />
                                                    <p>Add Due Payment</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon fas fa-th-large" />
                                            <p>
                                                Expenditure
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/add-expense" exact className="nav-link">
                                                    <i className="fas fa-plus nav-icon" />
                                                    <p>Add Expenditure</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/add-daily-history" exact className="nav-link">
                                                    <i className="fas fa-plus nav-icon" />
                                                    <p>Add History</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/view-expense" exact className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View Expenditure</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="" className="nav-link">
                                            <i className="nav-icon fas fa-th-large" />
                                            <p>
                                                Particulars
                                                <i className="fas fa-angle-left right" />
                                            </p>
                                        </a>
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/add-daily-history" exact className="nav-link">
                                                    <i className="fas fa-plus nav-icon" />
                                                    <p>Add Particulars</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                        {account_type === 'admin' ?
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <NavLink to="/admin/history" exact className="nav-link">
                                                    <i className="fas fa-eye nav-icon" />
                                                    <p>View Particulars</p>
                                                </NavLink>
                                            </li>
                                        </ul>                                        
                                        :
                                        <h1></h1>
                                        }
                                    </li>
                                    {account_type === 'admin' ?
                                        <li className="nav-item">
                                            <a href="" className="nav-link">
                                                <i className="nav-icon fas fa-chart-line" />
                                                <p>
                                                    Accounts
                                                    <i className="fas fa-angle-left right" />
                                                </p>
                                            </a>
                                            <ul className="nav nav-treeview">
                                                <li className="nav-item">
                                                    <NavLink to="/admin/drawer" exact className="nav-link">
                                                        <i className="fas fa-wallet nav-icon" />
                                                        <p>Drawer</p>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                            <ul className="nav nav-treeview">
                                                <li className="nav-item">
                                                    <NavLink to="/admin/monthly-report" exact className="nav-link">
                                                        <i className="fas fa-bar-chart nav-icon" />
                                                        <p>Monthly Report</p>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        :
                                        <h1></h1>
                                    }

                                    {/* <button className="btn btn-primary" onClick={this.onLogoutHandler}>Logout</button> */}
                                </ul>

                            </nav>
                            {/* /.sidebar-menu */}
                        </div>
                        {/* /.sidebar */}
                    </aside>
                </div>
            </div>
        )

    }
}
